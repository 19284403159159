import React from 'react';
import { FaRegCopy, FaPhone, FaEnvelope } from 'react-icons/fa';

import ContactForm from '../components/contactForm/contactForm';
import { IContactDetails, IImageConfig } from '../configuration/ui.config.models';
import './contactContainer.css';


function ContactContainer() {
  const email = "drbrooking@gmail.com";
  const phoneNumber = "+64 22 547 7254";
  return (
    <div className="Page-Container contact-container">
      <div className="grid-container">
        <h1 className="boldish grid-item1">Get in touch!</h1>


        <div className="grid-item3">
          <ContactForm />
        </div>

        <img className="grid-item4" src={require("../configuration/lowResolution/portrait/earmuffs_portrait_LR.JPG")} alt="Founder, Devon Brooking, in his earmuffs" />
        <div className="contact-details grid-item2">
          <p>
            <FaPhone/>
            <a className="contact-item" href={`tel:${phoneNumber}`}>{phoneNumber} &nbsp; </a>
            <FaRegCopy className="contact-item__copy" onClick={() => { navigator.clipboard.writeText(phoneNumber) }} />
          </p>
          <p>

            <FaEnvelope/>
            <a className="contact-item" href={`mailto:${email}`}>{email} &nbsp; </a>
            <FaRegCopy className="contact-item__copy" onClick={() => { navigator.clipboard.writeText(email) }} />
          </p>
        </div>
      
      </div>
    </div>
  );
}

export default ContactContainer;
