import React from 'react';
import { FaRegCopy } from 'react-icons/fa';

import ContactForm from '../components/contactForm/contactForm';
import WeedWacker from '../components/weedWacker/weedWacker';
import { IContactDetails, IImageConfig } from '../configuration/ui.config.models';
import './gameContainer.css';

function GameContainer() {
  return (
    <div className="gameContainer">
      <div className="grid-container">
        <WeedWacker />
      </div>
    </div>

  );
}

export default GameContainer;
