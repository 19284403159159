const getRandomIntegerInRange = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min; // Generate random integer in specified range [min, max]
}

const getRandomFloatInRange = (min: number, max: number) => {
    return Math.random() * (max - min + 1) + min; // Generate random integer in specified range [min, max]
}

export {
    getRandomIntegerInRange,
    getRandomFloatInRange
}