import { mainModule } from "process";

import { IUiConfig, IMenuItem, IMenuConfig, IContactDetails, pageTypes } from "./ui.config.models";
import placeholder from "./placeholderprofile.png"
// import outdoorFurniture from "./outdoorfurniture_beforeafter.png";
// import outdoorGutter from "./outdoorgutter_beforeafter.png";
// import outdoorWall from "./outdoorwall_beforeafter.png";

export const uiConfig: IUiConfig = {

    heading: "Devon Does It",
    contactDetails: {
        label: "Contact Devon",
        phoneNumber: "+64 22 547 7254",
        email: "drbrooking@gmail.com",
    },

    routes: [  {
        path: "/",
        title: "Welcome!" ,
        type: pageTypes.home,
        
    },
  

    { 
        path: "/contact",
        title: "Get in touch!" ,
        type: pageTypes.contact,
        
    },
    { 
        path: "/gallery",
        title: "" ,
        type: pageTypes.imageGrid,
     
    },
    { 
        path: "/play",
        title: "Play!" ,
        type: pageTypes.play,
    }
],
    menu: {
        home: {
            logo:{ 
                path: require("./logo.png"), 
                alt: "Devon on a roof clearing a gutter", 
                mobileOnly: false 
            },
            path: "/",
            menuLabel: "Devon Does It",
        },
        items: [ 

            { 
                menuLabel: "Services", 

                path: "/#services",
            },
            { 
                path: "/contact",
                menuLabel: "Contact", 
                type: pageTypes.contact,
            },
            // { 
            //     path: "/gallery",
            //     menuLabel: "Gallery", 
            //     title: "" ,
            //     type: pageTypes.imageGrid,
              
            // },
            { 
                path: "/play",
                menuLabel: "Play!", 
                title: "Play!" ,
                type: pageTypes.play,
            },
        ],
    }




} 

