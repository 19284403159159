import React from 'react';
import { FaCalendar, FaHome, FaTree } from 'react-icons/fa';

import './informationContainer.css';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { useLocation } from 'react-router-dom';


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  color: "#1c6536",

  background: "inherit",
  border: "none",
  margin: "10px",
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "inherit",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  fontSize: "100%",
  color: "#1c6536",
  boxShadow: "none",
  '& img': {
    borderRadius: "20px"
  },
  '& ul': {
    listStyle: "none"
  },
  '& a': {
    color: "#1c6536"
  },
  

}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ color: "#1c6536" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

function InformationContainer() {
const maxWidth480 = window.matchMedia("(max-width: 480px)").matches;
const scrolledRef = React.useRef(false);
const { hash } = useLocation();
const hashRef = React.useRef(hash)

  const serviceAreasPanels = [
    {
      label: 'Wellington',
      content: 'Aro Valley, Berhampore, Breaker Bay, Broadmeadows, Brooklyn, Churton Park, Crofton Downs, Glenside, Grenada North, Grenada Village, Hataitai, Highbury, Horokiwi, Houghton Bay, Island Bay, Johnsonville, Kaiwharawhara, Karaka Bays, Karori, Kelburn, Khandallah, Kilbirnie, Kingston, Lyall Bay, Mākara, Mākara Beach, Maupuia, Melrose, Miramar, Moa Point, Mornington, Mount Cook, Mount Victoria, Newlands, Newtown, Ngaio, Ngauranga, Northland, Ōhāriu, Oriental Bay, Owhiro Bay, Paparangi, Pipitea, Rongotai, Roseneath, Seatoun, Southgate, Strathmore Park, Takapū Valley, Tawa, Te Aro, Thorndon, Vogeltown, Wadestown, Wellington Central, Wilton, Woodridge',
    },
    {
      label: 'Porirua',
      content: 'Aotea, Ascot Park, Camborne, Cannons Creek, Mana, Papakowhai, Paremata, Plimmerton, Porirua, Porirua East, Ranui Heights, Takapuwahia, Titahi Bay, Waitangirua, Whitby',
    },
    {
      label: 'Hutt Valley',
      content: 'Alicetown,Avalon, Belmont, Boulcott, Days Bay, Eastbourne, Epuni, Fairfield, Gracefield, Harbour View, Haywards, Hutt Central, Kelson, Korokoro, Lowry Bay, Mahina Bay, Manor Park, Maungaraki, Melling, Moera, Naenae, Normandale, Petone, Point Howard, Seaview, Stokes Valley, Taita, Tirohanga, Wainuiomata, Waiwhetu, Waterloo, Woburn, York Bay',
    },
  ];

  const servicesPanels = [
    {
      label: 'Property care and repair',
      icon:  <FaHome/>,

      content: (
      <div>
        <ListItem>Water blasting </ListItem>
        <ListItem>House washing</ListItem>
        <ListItem>Painting</ListItem>
        <ListItem>Gutter cleaning</ListItem>
        
      </div>
      ),
    },
    {
      label: 'Gardening',
      icon:  <FaTree/>,

      content: (
        <div>
          <ListItem>Garden maintenance</ListItem>
          <ListItem>Tree & hedge trimming</ListItem>
          <ListItem>Mulching</ListItem>
          <ListItem>Weed spraying</ListItem>
          <ListItem>Lawn care</ListItem>
          <Item> <img src={require("../configuration/lowResolution/landscape/devonHedgeTrimming_LR.JPG")} alt="Devon trimming a hedge" /></Item>
        </div>
      ),
    },
    {
      label: 'Regular maintenance',
      icon:  <FaCalendar/>,
      content: <div>
        <p>We offer a range of serivces on a regular maintenance schedule for your home and garden. There are no obligations, and you can cancel at anytime. Get in touch for more information!</p>
    </div>
    },
  ]

  const paragraphStyle = {
    textAlign: "left",
    fontSize: "1rem",
    fontWeight: "600",
    color: "#1c6536",
  }

  React.useEffect(() => {
    if (hash) {
      // We want to reset if the hash has changed
      if (hashRef.current !== hash) {
        hashRef.current = hash;
        scrolledRef.current = false;
      }

      // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
      if (!scrolledRef.current) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          scrolledRef.current = true;
        }
      }
    }
  });
  return (
    <Box  sx={{ flexGrow: 1, padding: maxWidth480 ? "0px 20px 40px 20px" : "40px" }}>
      <Grid
        direction="row"
        justifyContent="center"
        alignItems="top"
        container
        spacing={4}
        >
        {/**************** what DDI does *************** */}
        <Grid xs={12} sm={7} md={6} alignSelf= "center">

          <Item >
            <h2 className="boldish text-content text-centered">
            <Link underline="hover" color="inherit">Devon </Link>
             and the team service the 
             <Link underline="hover" color="inherit" href="#service-regions"> greater Wellington region</Link>
             , to help with all of your
              <Link underline="hover" color="inherit" href="#services"> lawn, garden, and property </Link>
              needs.
            </h2>
          </Item>

        </Grid>
        <Grid xs={12} sm={5} md={6}>
          <Item> <img src={require("../configuration/lowResolution/landscape/profile_LR.JPG")} alt="Devon trimming a hedge" /></Item>
        </Grid>
       
        {/* *************** Services  ****************/}
        <Grid id="services" xs={12} sm={6} md={6}>
          <Item >

            <h2 className="boldish">Devon Does It Services</h2>
            <Box  >
              {servicesPanels.map((p) => {
                  return (
                  <Accordion sx={{position: "unset"}} >
                    <AccordionSummary sx={{
                      background: "none", 
                      margin: "0",

                      '> *': {
                        alignItems: "center", 
                        margin: "0",

                      },
                      'svg': {
                        paddingLeft: "10px"
                      }}} >
                        
                      <p>{p.label}</p>
                      {p.icon}
                    </AccordionSummary>
                    <AccordionDetails>
                        {p.content}
                    </AccordionDetails>
                  </Accordion>)
                })}
              </Box>

          </Item>
        </Grid>
       
        {/**************** Regions  ****************/}
        <Grid xs={12} sm={6} md={6} id="service-regions">
        <Item>
            <h2 className="boldish">Devon Does It Service Regions:</h2>
          <Box>

            {serviceAreasPanels.map((p) => {
              return (
              <Accordion sx={{position: "unset"}} >
                <AccordionSummary sx={{background: "none"}} >
                  {p.label}
                </AccordionSummary>
                <AccordionDetails>
                    {p.content}
                  
                </AccordionDetails>
              </Accordion>)
            })}</Box>

          </Item>

        </Grid>
        {/* <Grid xs={12} sm={6} md={6}>
          <Item> <img src={require("../configuration/lowResolution/landscape/devonRaking_LR.JPG")} alt="Devon does it, weed whacking" /></Item>
        </Grid> */}

        {/******************* devons intro  ****************/}
      
        <Grid xs={12} md={7}>
          <Item>
        <Box >
            <div id="who-is-devon" className="default-font">
              <h2 className="boldish">Hello, I'm Devon</h2>

              <p >
                I'm the founder of Devon Does It, a premier garden and property maintenance company serving the greater Wellington area.
              </p>
              <p  >
                I started Devon Does It in 2022 as a solo venture, driven by my passion for working in the outdoors and a keen eye for detail. Shortly after, my dad joined me, and we became a father-son duo dedicated to maintaining properties with our combined expertise. What started as a small family operation has grown into a successful business with a larger team.
              </p>
              <p >
                At Devon Does It, we believe in the power of a well-maintained property and garden to transform spaces and uplift spirits. So, let us do the hardwork while you experience the peace of mind that comes with knowing that your place is in good hands
              </p>

              <h3 className="boldish">Devon Brooking</h3>
              <i>Devon Does It, Founder</i>
            </div>
          </Box>
          </Item>
        </Grid>
        <Grid xs={12} md={5} alignSelf= "center">
          <Item> <img src={require("../configuration/lowResolution/landscape/sittingWithAWeed_LR.JPG")} alt="Devon, founder of Devon Does it smiles holding a weed" /></Item>
        </Grid>




      </Grid>
    </Box>
  )

}

export default InformationContainer;
