import React from 'react';
import emailjs from 'emailjs-com';
import './contactForm.css';

function ContactForm() {
    const {REACT_APP_EMAILJS_SERVICE_ID, REACT_APP_EMAILJS_TEMPLATE_ID, REACT_APP_EMAILJS_PUBLIC_KEY} = process.env
    
    const sendEmail = (e: any) => {
      e.preventDefault();


    const validateForm = (form: Array<any>) => {
        const email = form[1].value;
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if(!email || !email.match(isValidEmail)){
            const error = "Invalid email address";
            console.error(error);
            throw Error(error)
        }
        
        const text = form[3].value;
        if(!text || !text.length){
            const error = "No text input";
            console.error(error);
            throw Error(error)
        }
    }

    if(REACT_APP_EMAILJS_SERVICE_ID && REACT_APP_EMAILJS_TEMPLATE_ID && REACT_APP_EMAILJS_PUBLIC_KEY){

        try {
            const sendButton = e.target[4];
            sendButton.value = "Sending"
            validateForm(e.target);
            emailjs.sendForm(
                REACT_APP_EMAILJS_SERVICE_ID, 
                REACT_APP_EMAILJS_TEMPLATE_ID, 
                e.target, 
                REACT_APP_EMAILJS_PUBLIC_KEY
            )
            .then((result) => {
                sendButton.value = "Sent!"
                e.target.reset()
                setTimeout(() => { sendButton.value = "Send"; }, 2000);
            }, (error) => {
                throw Error(error.text);
            });
        } catch (error) {
            console.error(error);
            
        }
       
    }
    };
  
    return (
    <div className="Contact-Form">
      <form onSubmit={sendEmail}>
        <label>Name</label>
        <input type="text" name="from_name" />

        <label>Email</label>
        <input type="email" name="reply_to" />

        <label>Subject</label>
        <select id="subject" name="message_subject">
            <option value="Quote request">Quote request</option>
            <option value="Garden maintenance">Garden maintenance</option>
            <option value="Building maintenance">Building maintenance</option>
            <option value="Ongoing property maintenance">Ongoing property maintenance</option>
            <option value="Repairs">Repairs</option>

            <option value="Other">Other</option>


        </select>
        <label>Message</label>
        <textarea name="message" />

        <input  className="btn btn-round btn-lg btn-filled-orange" id="submit-button" type="submit" value="Send" />
      </form>
    </div>
    );
}

export default ContactForm;
