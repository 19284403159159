import React, { useState } from 'react'

import './galleryContainer.css';
import { IImageConfig } from "../configuration/ui.config.models"
interface IProps {
  images: Array<IImageConfig>;
}

function ImageGridItem({ image }: any) {

  function getSpanEstimate(size: any) {
    if (size > 3000) {
      return 3
    }
  
    return 2
  }

  const style = {
    gridColumnEnd: `span ${getSpanEstimate(image.width)}`,
    gridRowEnd: `span ${getSpanEstimate(image.height)}`,
  }

  return (
    <li style={style}>
      <div className="imageContainer">
        <img className="gallery-img" style={style} src={image.path} alt={image.alt} />
      </div>
    </li>
  )
}

function GalleryContainer({ images }: IProps) {

  return (
    <ul className="gallery-container gridContainer">
    {images.map((img, index) => {
        return (
          <ImageGridItem image={img}></ImageGridItem>
        );
      })}
    </ul>
    
  );
}

export default GalleryContainer;
