import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import MenuContainer from './components/menu/menuContainer';
import { uiConfig } from './configuration/ui.config';
import InformationContainer from './layout/InformationContainer';
import GameContainer from './layout/GameContainer';
import GalleryContainer from './layout/GalleryContainer';
import { FaRegCopy, FaPhone, FaRegEnvelope} from 'react-icons/fa';
import { pageTypes } from './configuration/ui.config.models';
import ContactContainer from './layout/ContactContainer';
import Footer from './layout/Footer';
// const mediaQuery = window.matchMedia("(max-width: 480px)");
// let showContactsInMenu = false;
// if (mediaQuery.matches) {
//  showContactsInMenu = true;
// } 
import galleryImages from './configuration/galleryImages';
const router = createBrowserRouter(uiConfig.routes.map((item) => {

  if(item.type === pageTypes.imageGrid) {

    return {
      path: item.path,
      element:
      <div className="Main-Container">
          <GalleryContainer images={galleryImages}/>
          
      </div>,

    }
    
  } else if (item.type === pageTypes.contact){

    return {
      path: item.path,
      element:
      <div className="Main-Container">
          <ContactContainer/>
          
          
      </div>,

    }

  } else if (item.type === pageTypes.play){

    return {
      path: item.path,
      element:
      <div className="Main-Container">
          <GameContainer />
          
          
      </div>,

    }

  }

  return  {
    path: item.path,
    element:
    <div className="Main-Container">

        <InformationContainer />
        
    </div>,
  }
}));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <header className="header">
        {/* TODO: seo stuff to go here? */}

        
      <MenuContainer items={uiConfig.menu.items} logo={uiConfig.menu.home}/>
        
      </header>
        <RouterProvider router={router} />

        <Footer/>
        
  </React.StrictMode>
);

//TODO:
// mobile design layout
// add photos
//

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();





