export default ({ left, src }: any) => {
    return <div  className="tree" style={{
      top: `41px`,
      left: left,
    }} >
      <img
        src={src}
        style={{
          height: `176px`,
        }} />
    </div>;
  }