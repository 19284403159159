const highScoreSass = [
    "You grow, girl!",
    "Pick'em weeds",
]
const flowerPickedSass = [
    "Dude, that's a flower",
    "Let me help, weeds are green. G-R-E-E-N",
    "Flowers are for everyone to enjoy",
    "C'mon buddy, just pick the weeds",
    "??",
    "Are you weeding in the dark?"
]
const lowScoreSass = [
    "Is everything okay at home?",
    "You're literally the first person to score this low",
    "I can help, but you have to want to help yourself",
    "Is there a trusted adult I can call?",
    "Hmm, okay",
    "Im worried about you."
]

const gameOverSass = [
    "Game over!\nCongrats, your low score wins you a free quote from DevonDoesIt.\nGet in touch!",
    "Game over!\nYour score is embarrassing, but your garden does'nt have to be!\nGet in touch!",
    "Game over!\nThe neighbors are talking, call now and it'll be okay!",
    "Game over!\nLet me help you help yourself, call now for a quote!",
    "Game over!\nDon't quit that day job, and leave the garden to the professionals!",
]

export {
    highScoreSass,
    flowerPickedSass,
    lowScoreSass,
    gameOverSass
}