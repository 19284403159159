

export interface IUiConfig {
    heading: string;
    contactDetails: IContactDetails
    menu: IMenuConfig
    routes: Array<IRoute>
    
}
  

export interface IMenuConfig {
    home: IMenuItem
    items: Array<IMenuItem>
}
export interface  IImageConfig { 
    path: string,
    alt: string, 
    mobileOnly: boolean, 
    height?: number, 
    width?: number
}

export enum pageTypes {
    imageGrid = "IMAGE_GRID",
    contact = "CONTACT",
    play = "PLAY",
    home = "HOME",
}
export interface IRoute {
    title?: string;
    path: string;
    type?: pageTypes.contact | pageTypes.imageGrid | pageTypes.play | pageTypes.home
}

export interface IMenuItem {
    menuLabel?: string;
    title?: string;
    path: string;
    theme?: string
    images?: Array< IImageConfig>;
    copy?: Array<any>;
    logo?: IImageConfig;
    type?: pageTypes.contact | pageTypes.imageGrid | pageTypes.play
    phoneNumber?: string,
    email?: string,
}

export interface IContactDetails {
    label: string;
    phoneNumber: string;
    email: string;
}