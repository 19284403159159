import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;

export default function MenuContainer({ items, logo}: any) {
  const maxWidth480 = window.matchMedia("(max-width: 480px)").matches;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', backgroundColor: "inherit"  }}>
         <a className="menu-logo" href='/'>
            <img src={logo.logo?.path} alt={logo.logo?.alt} /> 
          </a>
      <Divider />
      <List>
        {items.map((item: any) => (
          <ListItem key={item.menuLabel} disablePadding>
            <ListItemButton  
              href={ item.path }
              sx={{ 
                textAlign: 'left',
                color: "#1c6536",
               
               
              }}>
              <ListItemText sx={{
                  fontWeight: "900",
                }}
                disableTypography={true}
                primary={item.menuLabel} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="sticky" sx={{ backgroundColor: "transparent", boxShadow: "none", padding: "1rem"}} component="nav">
        <Toolbar  sx={{ 
          margin: "1rem",
          backgroundColor: "transparent",
          justifyContent: "space-between",
          '& a.menu-logo': {   width: maxWidth480 ? "60vw" : "37vw" },
          }} >
         
         
          <a className="menu-logo" href='/'>
            <img src={logo.logo?.path} alt={logo.logo?.alt} /> 
          </a>
          <IconButton
            
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ color: "#206438", mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {items.map((item:any) => (
              <Link underline="hover" key={item.menuLabel} variant="h5"  href={item.path} sx={{ fontWeight: '900', color: '#206438', padding: "10px" }}>
                {item.menuLabel}
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            // backgroundColor: "blue" ,
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { 
              borderRadius: "10px 0px 0px 10px",
              border: "10px solid #1c6536",
              boxSizing: 'border-box', 
              width: drawerWidth, 
              backgroundColor: "#ecbd55",
            },
            '& a.menu-logo': {   width: "34vw" },

          }}
        >
          {drawer}
        </Drawer>
      </nav>
      
    </Box>
  );
}
