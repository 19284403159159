const getLocalStorage = (key: string) => {
    try {
      const jsonData = localStorage.getItem(key);
      if (jsonData) {
        return JSON.parse(jsonData)
      } else {
        console.log(`No data found for key "${key}"`);
        return null;
      }
    } catch (error) {
      console.error('Error getting data from local storage:', error);
      return null;
    }
  }

const saveLocalStorage = (key: string, value: any): void =>  {
    try {
      const jsonData = JSON.stringify(value);
      localStorage.setItem(key, jsonData);
      console.log(`Data saved under key "${key}"`);
    } catch (error) {
      console.error('Error saving to local storage:', error);
    }
  }

export {
    getLocalStorage,
    saveLocalStorage
}