import React, { ReactElement } from 'react';
import { FaGoogle, FaInstagram } from 'react-icons/fa';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import './footer.css';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
// CONTACT US
// Oliver's Army
// +64 27 510 5950
// oliver@oliversarmy.net

// Newtown,
// Wellington 6021,
// New Zealand

// OUR SERVICES
// Development
// Regular Maintenance
// Lawn
function Footer() {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: "#206438",
        ...theme.typography.body2,
        padding: theme.spacing(1),

        color: "#ebbc5e",
        background: "inherit",
        boxShadow: "none",
        '& a': {
            textDecoration: "none",
            color: "#ebbc5e",

        },
        '& ul': {
            color: "#ebbc5e",
            listStyleType: "none",
            padding: "0"
        }
    }));
    return (
        <Box className="footer" sx={{ flexGrow: 1, width: "100%", contain: "content", textAlign: "left"}}>
            <Grid container spacing={4} sx={{
                textAlign: 'center',
                justifyContent: "center",
                padding: "0 17%" 
            }}>

                <Grid xs={12} md={6}>
                    <Item>
                        <h2> Contact Us</h2>


                        <ul>
                            <li> Devon Does It</li>
                            <li><a target="_blank" rel="noopener" type="website" href="tel:+64225477254">+64 22 5477 254</a> </li>
                            <li><a target="_blank" rel="noopener" type="page" href="mailto:drbrooking@gmail.com">drbrooking@gmail.com</a></li>
                            <br/>
                            <li>Newtown</li>
                            <li>Wellington 6021</li>
                            <li>New Zealand</li>
                        </ul>
                        <div >
                            <a
                                className="social-link"
                                href="https://www.google.com/maps/place/Devon+Does+It/@-41.2529602,174.7542577,11z/data=!4m8!3m7!1s0xab1f211897b85e83:0xaa2d76cdf2353a32!8m2!3d-41.2529602!4d174.7542577!9m1!1b1!16s%2Fg%2F11sk2_ng86?entry=ttu"
                                target="_blank"
                                rel="noreferrer"
                            ><FaGoogle /></a>
                            <a
                                className="social-link"
                                href="https://www.instagram.com/devondoesit.co.nz/"
                                target="_blank"
                                rel="noreferrer"><FaInstagram /></a>
                        </div>

                    </Item>

                </Grid>
                <Grid xs={12} md={6}>
                    <Item>
                        <h2> Services</h2>
                        <ul>
                            <li>Regular maintenance </li>
                            <li>Water blasting </li>
                            <li>House washing</li>
                            <li>Painting</li>
                            <li>Gutter cleaning</li>
                            <li>Garden maintenance</li>
                            <li>Tree & hedge trimming</li>
                            <li>Mulching</li>
                            <li>Weed spraying</li>
                            <li>Lawn care</li>
                        </ul>
                    </Item>

                </Grid>


                <Grid xs={12} md={6}>
                    <Item>

                    </Item>

                </Grid>


            </Grid>
        </Box>
    );
}

export default Footer;
