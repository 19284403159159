import React, { useState, useEffect, useRef } from 'react';

export default () => {
    const [isLandscape, setIsLandscape] = useState(window.matchMedia("(orientation: landscape)").matches);
  
    useEffect(() => {
      const onOrientationChange = () => {
        setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
      };
  
      window.addEventListener("orientationchange", onOrientationChange);
  
      return () => {
        window.removeEventListener("orientationchange", onOrientationChange);
      };
    }, []);
  
    return isLandscape;
  };
