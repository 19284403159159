import React, { useState, useEffect, useRef } from 'react';
import './weedWacker.css';
import house from "./house34.png";
import {
  useNavigate,
} from "react-router-dom";
import useOrientation from './helpers/useOrientation';
import useMobile from './helpers/useMobile';
import { getLocalStorage, saveLocalStorage } from './helpers/localStorage';

import {
  getRandomIntegerInRange,
  getRandomFloatInRange
} from './helpers/numberGenerators'
import {
  highScoreSass,
  flowerPickedSass,
  lowScoreSass,
  gameOverSass
} from './text/sass'
import Van from './game-components/van';
import House from './game-components/house';
import Background from './game-components/background';
import Tree from './game-components/tree';

interface IPlant {
  plantKey?: string;
  onClickFn?: (e: any) => void,
  type?: string;
  left: string;
  width: string;
  top?: string;
  src: string;
  bottom?: string;
}
const WeedWacker = () => {
  const mobile = useMobile();
  const isLandscape = useOrientation();
  const navigate = useNavigate();
  const spawnIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const gameIntervalRef = useRef<NodeJS.Timeout | null>(null);
  let speed = 1000;
  const [score, setScore] = useState(0);
  var [weeds, setWeeds] = useState<IPlant[]>([]);
  var [plants, setPlants] = useState<IPlant[]>([]);
  const [gameOver, setGameOver] = useState(false);
  const [sass, setSass] = useState("Pick'em weeds!");
  const [usersHighScore, setUsersHighScore] = useState(getLocalStorage("user-high-score"));
  const [isResetting, setIsResetting] = useState(true);
  const [houseLeft, setHouseLeft] = useState("");
  const [houseImg, setHouseImg] = useState("");
  const [treesLeft, setTreesLeft] = useState(["", ""]);
  const [treesImg, setTreesImg] = useState(["", ""]);
  const [backgroundHeight, setBackgroundHeight] = useState("");

  const gameOverRef = useRef(gameOver);
  const weedsRef = useRef(weeds);
  const scoreRef = useRef(score)
  const leaderboard = [
    // { name: "Lauren is da bomb", score: '1 million',  display: true},
    { name: "DevonDoesIt rulez", score: 9000, display: true },
    { name: "SEAN1999", score: getRandomIntegerInRange(100,8999), display: Math.random() > 0.7 },
    { name: "bob", score: getRandomIntegerInRange(100,8999), display: Math.random() > 0.7 },
    { name: "luiz m.", score: getRandomIntegerInRange(100,8999), display: Math.random() > 0.7 },
    { name: "HHH", score: getRandomIntegerInRange(100,8999), display: Math.random() > 0.7 },
    { name: "ALC", score: getRandomIntegerInRange(100,8999), display: Math.random() > 0.7 },
    { name: "MJC", score: getRandomIntegerInRange(100,8999), display: Math.random() > 0.7 },
    
    ...(usersHighScore && usersHighScore > 0 ? [{ name: "You", score: usersHighScore, display: true}] : [])
  ]
  useEffect(() => {
    gameOverRef.current = gameOver;

    if (gameOver) {
      setSass(pickRandomPhrase(gameOverSass, true));

      clearInterval(spawnIntervalRef.current!);
      clearInterval(gameIntervalRef.current!);
      saveLocalStorage("user-high-score", usersHighScore)
    }
  }, [gameOver]);


  useEffect(() => {
    scoreRef.current = score;
    if (usersHighScore < scoreRef.current) {
      setUsersHighScore(scoreRef.current)
    }

  }, [score]);

  useEffect(() => {
    weedsRef.current = weeds;
    if (weeds?.length > 10) {
      setGameOver(true);
    }
  }, [weeds]);

  useEffect(() => {
    return () => {
      clearInterval(spawnIntervalRef.current!);
      clearInterval(gameIntervalRef.current!);
    };
  }, []);

  const resetPlants = () => {

    const newWeeds: IPlant[] = [];
    const newPlants: IPlant[] = [];
    for (let index = 0; index < 2; index++) {
      const weed: IPlant = getPlant("weed");
      newWeeds.push(weed);
      const plant = getPlant("plant");
      newPlants.push(plant);
    }
    setWeeds(newWeeds);
    setPlants(newPlants)
  };
  const pickRandomPhrase = (arr: string[], force?: boolean): string => {
    const returnSass = force ? true : Math.random() > 0.7;
    if (!returnSass || arr.length === 0) {
      return ""; // or throw an error, or handle empty array case as needed
    }
    const randomIndex = Math.floor(Math.random() * arr.length);
    const item = arr[randomIndex];
    arr.splice(randomIndex, 1);
    return item;
  }

  const getPlant = (type: string): IPlant => {
    const top = `${getRandomFloatInRange(191, 360)}px` // `${(Math.random() * 360) }px`;
    const left = `${Math.random() * 560}px`;
    let width = `${getRandomIntegerInRange(10, 30)}px`;
    const plantKey = `${type}-${top}-${left}`
    const img = document.createElement('img');
    let src = ""
    switch (type) {
      case "weed":
        width = `${getRandomIntegerInRange(10, 50)}px`;
      
        src = require(`./images/weed${getRandomIntegerInRange(1, 5)}.png`)
        break;
      case "mushie":
        width = `${getRandomIntegerInRange(10, 20)}px`;
        src = require(`./images/mushie${getRandomIntegerInRange(2, 2)}.png`)

        break;
      case "food":
        src = require(`./images/food${getRandomIntegerInRange(1, 1)}.png`)
        break;
      case "plant":
        src = require(`./images/flower${getRandomIntegerInRange(1, 7)}.png`);

        break;

      default:
        src = require(`./images/flower${getRandomIntegerInRange(1, 7)}.png`);
        break;
    }

    const mouseClickAnimation = (event: MouseEvent, type: string) => {
      const x = event.clientX;
      const y = event.clientY;
      const radius = 5;
      const colours = [
        "#fd00ff",
        "#fdff00",
        "#00ff38",
        "#00f9ff",
        "#3c00ff",
        "#fd00ff",
        "#fdff00",
        "#00ff38",
        "#00f9ff",
        "#3c00ff"].reverse()

      const canvas = document.createElement('canvas');
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      canvas.classList.add('rainbow-canvas');
      document.body.appendChild(canvas);

      const ctx = canvas.getContext('2d');

      let index = 0;
      const points = getRandomIntegerInRange(6, 12)

      function drawStar(
        ctx: CanvasRenderingContext2D | null,
        x: number,
        y: number,
        radius: number,
        points: number,
        colour: string) {
        const step = Math.PI / points;
        if (ctx) {
          ctx.beginPath();
          ctx.moveTo(x, y - radius);
          for (let i = 0; i < 2 * points; i++) {
            const angle = i * step;
            const length = i % 2 === 0 ? radius : radius / 2;
            ctx.lineTo(x + Math.sin(angle) * length, y - Math.cos(angle) * length);
          }
          ctx.closePath();
          ctx.strokeStyle = colour;
          ctx.lineWidth = 2;
          ctx.stroke();
        }
      }

      function drawNextLayer() {
        if (index < colours.length) {
          if(type === "mushie"){
            drawStar(ctx, x, y, radius + (index * 5), points, colours[index]);
          } 
          index++;
          requestAnimationFrame(drawNextLayer);
        } else {
          setTimeout(() => {
            document.body.removeChild(canvas);
          }, 250); 
        }
      }
      drawNextLayer();
    }

    const onPlantClick = (pk: string, e: MouseEvent) => {
      if (gameOverRef.current) return;


      mouseClickAnimation(e, type)

      if (type === 'weed') {
        setSass(pickRandomPhrase(highScoreSass));

        setWeeds((prevWeeds) => prevWeeds.filter((w) => w.plantKey !== pk));
        setScore((prevScore) => prevScore + 100);

      } else if (type === "food") {
        setSass("That's not a weed! Thats food! We're in a cost of living crisis!")

        setPlants((prevPlants) => prevPlants.filter((w) => w.plantKey !== pk));
        setScore((prevScore) => prevScore - 500);

      } else if (type === "mushie") {
        setWeeds((prevWeeds) => prevWeeds.filter((w) => w.plantKey !== pk));
        setScore((prevScore) => prevScore + getRandomIntegerInRange(50, 600));

      } else {
        setPlants((prevPlants) => prevPlants.filter((w) => w.plantKey !== pk));
        setScore((prevScore) => prevScore - 200);
        if (scoreRef.current < 0) {

          setSass(pickRandomPhrase(lowScoreSass));
          return;
        }
        setSass(pickRandomPhrase(flowerPickedSass));

      }
      return;
    };

    return {
      plantKey, onClickFn: (e: any) => onPlantClick(plantKey, e), type, left,
      width,
      top, src
    }
  }

  const spawnPlant = () => {
    if (gameOverRef.current) return;
    const isWeedNum = Math.random();
    const isMushieNum = Math.random();
    const isFoodNum = Math.random();

    const isWeed = isWeedNum > 0.7 && isWeedNum < 1;
    const isMushie = isMushieNum > 0.6 && isMushieNum < 0.7;
    const isFood = isFoodNum > 0.5 && isFoodNum < 0.6;

    if (isWeed) {
      setWeeds((prevWeeds) => {
        const plant = getPlant('weed');
        return [...prevWeeds, plant]

      })
    } else if (isMushie) {
      setWeeds((prevWeeds) => {
        const plant = getPlant('mushie');
        return [...prevWeeds, plant]
      })
    } else if (isFood) {
      setPlants((prevWeeds) => {
        const plant = getPlant('food');
        return [...prevWeeds, plant]
      })
    } else {

      setPlants((prevWeeds) => {
        const plant = getPlant('plant');
        return [...prevWeeds, plant]
      })
    }
  };

  const increaseDifficulty = () => {
    speed = Math.max(200, speed - 100);

    clearInterval(spawnIntervalRef.current!);
    spawnIntervalRef.current = setInterval(spawnPlant, speed);
  };

  const onClickCallDevon = () => {

    navigate("/contact");
  }

  const startGame = () => {
    setGameOver(false);

    setIsResetting(true)
    setSass("");
    resetPlants();
    speed = 1000;
    setHouseLeft(`${getRandomFloatInRange(0, 350)}px`);
    setHouseImg(require(`./images/house${getRandomIntegerInRange(1, 3)}.png`));

    setTreesLeft([`${getRandomFloatInRange(0, 350)}px`, `${getRandomFloatInRange(0, 350)}px`])
    setTreesImg([require(`./images/tree${getRandomIntegerInRange(1, 2)}.png`), require(`./images/tree${getRandomIntegerInRange(1, 2)}.png`), require(`./images/tree${getRandomIntegerInRange(1, 2)}.png`)])
    setBackgroundHeight(`${getRandomFloatInRange(137, 217)}px`)
    setScore(0);

    clearInterval(spawnIntervalRef.current!);
    clearInterval(gameIntervalRef.current!);

    setIsResetting(false);
    spawnIntervalRef.current = setInterval(spawnPlant, speed);
    gameIntervalRef.current = setInterval(increaseDifficulty, 5000);
  };

  const TextWithLineBreaks = ({ text }: any) => {
    return (
      <div>
        {text.split('\n').map((item: string, index: number) => (
          <React.Fragment key={index}>
            {item}
            <br />
          </React.Fragment>
        ))}
      </div>
    );
  }

  const WelcomeView = () => {
    return (
      <div id="game-area">
        <div className="game-text" id="version"> V1.0 </div>
        <Background left={200} height={200} />


        <Tree src={require(`./images/tree1.png`)} left={75} />
        <Tree src={require(`./images/tree2.png`)} left={475} />

        <House src={require(`./images/house3.png`)} left={250} />
        <House src={require(`./images/house1.png`)} left={50} />
        <Tree src={require(`./images/tree1.png`)} left={25} />

        <House src={require(`./images/house2.png`)} left={150} />
        <Tree src={require(`./images/tree1.png`)} left={325} />
        <Tree src={require(`./images/tree2.png`)} left={150} />

        {Array.from(Array(10)).map((x) => {
          return {
            plantKey: "plant1",
            left: `${getRandomIntegerInRange(1, 218)}px`,
            width: `${getRandomIntegerInRange(25, 40)}px`,
            bottom: "-5px",
            src: require(`./images/weed${getRandomIntegerInRange(1, 5)}.png`)
          }
        }).map(makePlantEl)}
      <div className="game-text" id="developedBy">Developed by   <a href="https://www.linkedin.com/in/lauren-colls-87b703109/">Lauren </a> </div>

 {Array.from(Array(10)).map((x) => {
          return {
            plantKey: "plant2",
            left: `${getRandomIntegerInRange(341, 560)}px`,
            width: `${getRandomIntegerInRange(25, 40)}px`,
            bottom: "-5px",
            src: require(`./images/weed${getRandomIntegerInRange(1, 5)}.png`)
          }
        }).map(makePlantEl)}

        <div className="game-area-header">
          <h1 className="game-text" id="game-title" > WackaWeed </h1>

        </div>
        <div className="game-text" id="leaderboard" >
          <h2> High Scores</h2>
          <div className="grid-wrapper">
            <div className="grid-heading" >Rank</div>
            <div className="grid-heading">Name</div>
            <div className="grid-heading">Score</div>
            {leaderboard.filter((p) => p.display ).sort((a, b) => b.score - a.score).map((lb, i) => {
              return i >= 4 ? null : (<> <div className="rank">{
                i === 0 ? "1st" : i === 1 ? "2nd" : "3rd"
              }</div>
                <div>{lb.name}</div>
                <div>{lb.score}</div></>)

            })}

          </div>
        </div>

        <div className="button-panel">
          <div className="game-text button" id="start-game-div" onClick={startGame}>
            Play!
          </div>
        </div>
      </div>
    )
  }

  const makePlantEl = ({
    plantKey,
    onClickFn,
    type,
    left,
    width,
    top,
    src,
    bottom,
  }: IPlant) => {

    return (<div
      key={plantKey + left + top}
      onClick={onClickFn}
      className={type}
      style={{
        position: "absolute",
        left,
        width,
        top,
        bottom,
      }} >
      <img
        src={src}
      />
    </div>);
  }

  const PlayView = () => {

    return (
      <div id="game-area">
        <div className="game-text" id="scoredboard"> {score}pts </div>
        <Background height={backgroundHeight} />
        <House src={houseImg} left={houseLeft} />
        <Tree src={treesImg[0]} left={treesLeft[0]} />
        <Tree src={treesImg[1]} left={treesLeft[1]} />

        {plants.map(makePlantEl)}
        {weeds.map(makePlantEl)}
        {sass?.length && sass?.length > 0 ? <div className="game-text bubble grow left">{<TextWithLineBreaks text={sass} />}</div> : null}
        {gameOver ? <Van /> : null}
        {gameOver ? (<div className="button-panel">

          <div className="game-text button" id="call-devon" onClick={onClickCallDevon}>
            Call Devon!
          </div>
          <div className=" game-text button" id="retry" onClick={startGame}>
            Retry!
          </div>
        </div>) : null
        }


      </div>

    )
  }
  return (
    <div className="Page-Container Weed-Wacker">
      {mobile && !isLandscape ? (
        <div className="rotate-message game-text">
          <p>Please rotate your device to landscape to play.</p>
        </div>) :
        isResetting ? <WelcomeView /> : <PlayView />}



    </div>
  );
};

export default WeedWacker;
