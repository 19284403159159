export default [ 
    { 
        path: require("./lowResolution/landscape/devonWithHisMower_LR.JPG"), 
        alt: "Devon crouches with his lawn mower", 
        mobileOnly: false, height: 2889, width: 4333 
    },
    // { path: outdoorGutter, alt: "Before and after photos of clear gutters", mobileOnly: false, height: 2889, width: 4333 },
    
    { 
        path: require("./lowResolution/landscape/devonWithHisWeedWhacker_LR.JPG"), 
        alt: "Devon holding his weed eater", 
        mobileOnly: false, height: 2889, width: 4333 
    },


    { 
        path: require("./lowResolution/landscape/joshWeedWacking_landscape_LR.jpg"), 
        alt: "Josh holding a weed eater", 
        mobileOnly: false, height: 2889, width: 4333 
    },


    { 
        path: require("./lowResolution/landscape/devonHedgeTrimming_LR.JPG"), 
        alt: "Devon trimming a hedge", 
        mobileOnly: false, height: 2889, width: 4333 
    },
    { path: require("./lowResolution/portrait/mower_portrait_LR.JPG"), alt: "Devon standing with his mower", mobileOnly: true, width: 2889, height: 4333},

    { 
        path: require("./lowResolution/landscape/onRoof_LR.JPG"), 
        alt: "Devon on a roof clearing a gutter", 
        mobileOnly: false, height: 2889, width: 4333 
    },

    { 
        path: require("./lowResolution/portrait/joshRaking_portrait_LR.jpg"), 
        alt: "Josh raking", 
        mobileOnly: false, width: 2889, height: 4333 
    },
    // { path: outdoorFurniture, alt: "Before and after photos of cleaned outdoor furniture", mobileOnly: false, height: 2889, width: 4333 },


    { 
        path: require("./lowResolution/landscape/devonWashingWindow_LR.JPG"), alt: "Wellington Devon cleans windows", 
        mobileOnly: false, height: 2889, width: 4333 
    },

    { path: require("./lowResolution/landscape/standingWithAWeed_LR.JPG"), alt: "Devon weeding", mobileOnly: false, height: 2889, width: 4333 },
    { path: require("./lowResolution/landscape/profile3_LR.JPG"), alt: "Devon smiling while looking after flowers in a garden", mobileOnly: false, height: 2889, width: 4333 },
    // { path: outdoorWall, alt: "Before and after photos of an exterior wall that has been painted", mobileOnly: false, height: 2889, width: 4333 },
    { path: require("./lowResolution/landscape/devonHosing_LR.JPG"), alt: "Devon holding a hose and sponge", mobileOnly: false, height: 2889, width: 4333 },
    { path: require("./lowResolution/landscape/devonRaking_LR.JPG"), alt: "Devon raking leaves on a garden lawn", mobileOnly: false, height: 2889, width: 4333 },


    // { path: require("./lowResolution/portrait/hedgetrimming_portrait_LR.JPG"), alt: "Devon trimming a hedge", mobileOnly: true, width: 2889, height: 4333},
]